import React from "react";

function SrrHeader(props) {
  let url = "";
  switch (props.breadcrumb_path2) {
    case "PRIVACY IMPACT CENTER":
      url = "/privacyimpactcenter";
      break;
    case "BUSINESS QUESTIONNAIRE":
      url = "/businessquestionnaire";
      break;
    case "MONITORING AND TESTING":
      url = "/monitoringandtesting";
      break;
    default:
      break;
  }
  return (
    <>
      <div className="heading">
        <div className="container">
          <h1>{props.heading}</h1>
        </div>
      </div>
      <div className="sub-heading bg-gray margin-bottom-30" id="request-form">
        <div className="container">
          <ul className="wirewheel-breadcrumb">
            <li>
              <a className="sub-heading--wirewheel-anchor" href="/">
                {props.breadcrumb_path1}
              </a>
            </li>
            <li>
              <a className="sub-heading--wirewheel-anchor" href={url}>
                {props.breadcrumb_path2}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default SrrHeader;
