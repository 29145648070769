import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./shared/components/Header";
import Footer from "./shared/components/Footer";
import CenterOfExcellence from "./components/CenterOfExcellence";
import BusinessQuestionnaire from "./components/BusinessQuestionnaire";
import MonitoringandTesting from "./components/MonitoringandTesting";
import PrivacyImpactCenter from "./components/PrivacyImpactCenter";

import "./App.scss";

function App() {
  return (
    <>
      <Header />
      <Router>
        <Switch>
          <Route path="/" exact component={CenterOfExcellence} />
          <Route
            path="/businessquestionnaire"
            component={BusinessQuestionnaire}
          />
          <Route path="/privacyimpactcenter" component={PrivacyImpactCenter} />
          <Route
            path="/monitoringandtesting"
            component={MonitoringandTesting}
          />
        </Switch>
      </Router>
      <Footer />
    </>
  );
}

export default App;
